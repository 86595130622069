import * as React from 'react'
import { FormattedMessage } from 'react-intl';


import IndexLayout from '../layouts'
import LilaSection from '../components/LilaSection'
import styled from '@emotion/styled'

import ShippingBook from '../components/shipping/ShippingBook'
import ShippingNav from '../components/nav/ShippingNav'
import {

  DHLIcon_2,
  DPDIcon, FedExIcon,
  GLSIcon,
  HermesIcon,
  UPSIcon
} from '../constants/shippingSolutionIcons'
import { fonts } from '../styles/variables'
var Scroll = require('react-scroll');
var Element = Scroll.Element;



const Chapter = styled.div`
width:100%;
display:flex;
padding-left: 8rem;
padding-right: 12rem;
padding-top: 4rem;
text-align:left;

 @media screen and (max-width: 1199px) {
padding-left: 10%;
padding-right: 10%;

}
`

const ChapterText = styled.div`
width:70%;
margin-bottom:4rem;

 @media screen and (max-width: 1199px) {

width:100%;

}


`

const SubP = styled.p`
text-align: left;
margin-top:1.2rem;
margin-bottom:6rem;
letter-spacing:1px;
`
const SubP_2 = styled.p`
text-align: left;
margin-top:0.7rem;
margin-bottom:1rem;
letter-spacing:1px;
`

const P = styled.p`
text-align: left;
letter-spacing:1px;
font-size:1rem !important;
margin-bottom:0px;
padding-bottom:0px;
display:flex;
`



const H1 = styled.h1`
font-size:2rem;
font-family:${fonts.bcExtraLight};
`



const LilaHermes = styled.div`
#svg2{width:250px !important;}
`

const LilaUPS = styled.div`
.lilaUPS{width:90px !important;}

`

const LilaDHL = styled.div`
#Ebene_1{width:250px;}

`


const LilaGLS = styled.div`
.lilaGLS{
width:180px;
}
`

const LilaDPD = styled.div`
#svg2{
width:150px;
}
`

const LilaFedEx = styled.div`

#Layer_1 {
width:150px;
}
`


const Versand = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <ShippingBook title={<FormattedMessage id={'shipping_0'}/>} subTitle1={''} subTitle2={''}/>


    <Element id="chOne"/>
    <div id={'section-1'}>
      <LilaSection id={'first'} title={''}></LilaSection>
      <Chapter id={'chapter1'}>
        <ShippingNav />
        <ChapterText>
          <H1><FormattedMessage id={'shipping_header_1'}/> </H1>
          <SubP></SubP>
          <LilaHermes>
            <HermesIcon/>
          </LilaHermes>



        </ChapterText>
      </Chapter>
    </div>

    <Element id="chTwo"/>
    <div id={'section-2'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter2'}>
        <ShippingNav />
        <ChapterText>
          <H1><FormattedMessage id={'shipping_header_2'}/> </H1>
          <SubP></SubP>
<LilaUPS><UPSIcon></UPSIcon></LilaUPS>

        </ChapterText>
      </Chapter>

    </div>


    <Element id="chThree"/>
    <div id={'section-3'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter3'}>
        <ShippingNav />
        <ChapterText>
          <H1> <FormattedMessage id={'shipping_header_3'}/></H1>
          <SubP></SubP>
          <LilaDHL><DHLIcon_2/></LilaDHL>




        </ChapterText>
      </Chapter>
    </div>

    <Element id="chFour"/>
    <div id={'section-4'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter4'}>
        <ShippingNav />
        <ChapterText>
          <H1> <FormattedMessage id={'shipping_header_4'}/></H1>
          <SubP_2></SubP_2>

          <LilaGLS><GLSIcon/></LilaGLS>



        </ChapterText>
      </Chapter>
    </div>

    <Element id="chFive"/>
    <div id={'section-5'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter5'}>
        <ShippingNav />
        <ChapterText>
          <H1> <FormattedMessage id={'shipping_header_5'}/></H1>
          <SubP></SubP>
<LilaDPD><DPDIcon/></LilaDPD>



        </ChapterText>
      </Chapter>
    </div>



    <Element id="chSix"/>
    <div id={'section-6'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter6'}>
        <ShippingNav />
        <ChapterText>
          <H1> <FormattedMessage id={'shipping_header_6'}/></H1>
          <SubP></SubP>
          <LilaFedEx><FedExIcon/></LilaFedEx>

        </ChapterText>
      </Chapter>
    </div>




  </IndexLayout>
)

export default Versand
