import * as React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';
import Scrollspy from 'react-scrollspy'
import { fonts } from '../../styles/variables'

var Scroll = require('react-scroll');

var scroller = Scroll.scroller;



const StyledSticky = styled.div`
width:30%;
height:200px !important;
position: -webkit-sticky;
position: sticky;
top: 150px;

 @media screen and (max-width: 1199px) {
display:none;

}
`
const LilaNav = styled.div`
font-family:${fonts.bcHairLine};
ul{
color:var(--txt);


}
.is-current{
color:#663399 !important;
font-family:${fonts.bcBook};
}
line-height:1.5rem;
font-size:1rem;
display:block;
padding-bottom:4rem;
`

const LilaLink = styled.a`
width:100%;
font-size:0.8rem;
color:var(--txt);

`

const LilaFocus = styled.div`
cursor:pointer;

.is-current{
color:#663399 !important;
font-family:${fonts.bcBook};
}
`


class ShippingNav extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }



  componentDidMount() {

  }

  scrollToChapter1 () {
      scroller.scrollTo('chOne', {
      duration: 1500,
      smooth: true,
    })
}
  scrollToChapter2 () {
      scroller.scrollTo('chTwo', {
      duration: 1500,
      smooth: true,
    })
}
  scrollToChapter3 () {
      scroller.scrollTo('chThree', {
      duration: 1500,
      smooth: true,
    })
}

scrollToChapter (chapterID) {
      scroller.scrollTo(chapterID, {
      duration: 1500,
      smooth: true,
    })
}


  render() {
    return(



        <StyledSticky  >
        <LilaNav>
          <Scrollspy items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5', 'section-6'] } currentClassName="is-current">
<LilaFocus onClick={this.scrollToChapter1}><LilaLink ><FormattedMessage id={'shipping_header_1'}/></LilaLink></LilaFocus>
<LilaFocus onClick={this.scrollToChapter2} ><LilaLink><FormattedMessage id={'shipping_header_2'}/></LilaLink></LilaFocus>
<LilaFocus onClick={this.scrollToChapter3} ><LilaLink><FormattedMessage id={'shipping_header_3'}/></LilaLink></LilaFocus>
<LilaFocus onClick={()=>this.scrollToChapter('chFour')} ><LilaLink><FormattedMessage id={'shipping_header_4'}/></LilaLink></LilaFocus>
<LilaFocus onClick={()=>this.scrollToChapter('chFive')} ><LilaLink><FormattedMessage id={'shipping_header_5'}/></LilaLink></LilaFocus>
<LilaFocus onClick={()=>this.scrollToChapter('chSix')} ><LilaLink><FormattedMessage id={'shipping_header_6'}/></LilaLink></LilaFocus>


          </Scrollspy>
        </LilaNav>

        </StyledSticky>




    )
  }
}


export default ShippingNav


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
